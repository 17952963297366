.rootPage * {
  margin: 0;
  padding: 0;
  color: white;
  font-family: "Inter", Tahoma, Geneva, Verdana, sans-serif;
  background: none;
  border: none;
}

body {
  background-color: #181a1f;
}

/* Header */
.header {
  padding: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  font-size: 36px;
  font-weight: 700;
}

.loginButton {
  font-size: 14px;
  font-weight: 500;
  border: 1px solid white;
  height: 40px;
  padding: 0 24px;
  border-radius: 20px;
  cursor: pointer;
}

.loginButton:hover {
  background-color: #202228;
}

.content {
  max-width: 1200px;
  margin: auto;
}

.marginWrapper {
  margin: 0 48px;
}

/* Hero */
.hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 48px;
  margin-bottom: 96px;
}

.heroHeading {
  font-size: 72px;
  font-weight: 700;
  margin-bottom: 24px;
}

.heroSubHeading {
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 72px;
}

.heroButton {
  cursor: pointer;
  box-sizing: border-box;
  background: linear-gradient(278deg, #b4d200 17.99%, #9740c0 80.89%);
  color: #181a1f;
  border-radius: 40px;
  font-weight: 800;
  font-size: 28px;
  height: 80px;
  padding: 0 24px;
  margin-bottom: 24px;
}

.heroButton:hover {
  border: 2px solid white;
  padding: 0 22px;
  color: white;
}

.heroData {
  font-size: 24px;
}

.heroTextContainer {
  flex: 1;
}
.heroImageContainer {
  flex: 1;
  text-align: right;
}

.heroImage {
  width: 100%;
  max-width: 480px;
}

/* Section */
.section {
  padding: 24px 0;
}

.sectionHeaderWrapper {
  text-align: center;
}

.sectionHeader {
  display: inline-block;
  text-align: left;
}

.step {
  color: #9740c0;
  font-size: 24px;
  font-weight: 400;
}

.sectionHeading {
  font-size: 48px;
  font-weight: 700;
}

.sectionUnderline {
  height: 8px;
  width: 60px;
  background: #9740c0;
  margin-bottom: 64px;
}

.featureContainer {
  display: flex;
  justify-content: center;
  gap: 64px;
  padding: 0 64px;
}

.feature {
  flex: 1;
  background-color: #202228;
  border-radius: 40px;
  padding: 48px;
  max-width: 480px;
  text-align: left;
}

/* .feature:hover {
  border: 2px solid white;
  padding: 46px;
  background-color: #181a1f;
} */

.featureHeading {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 8px;
}

.featureSubHeading {
  font-size: 18px;
  font-weight: 400;
}

.featureImageContainer {
  text-align: center;
  margin-bottom: 48px;
}

/* CTA */
.cta {
  padding: 64px;
  background: linear-gradient(278deg, #b4d200 13.61%, #9740c0 73.49%), #f0f0f0;
  margin: 64px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 40px;
}

.ctaHeading {
  font-size: 64px;
  font-weight: 700;
  margin-bottom: 14px;
}

.ctaSubHeading {
  font-size: 36px;
  font-weight: 500;
}

.ctaTextWrapper {
  flex: 2;
}

.ctaButtonWrapper {
  flex: 1;
  text-align: right;
}

.ctaButton {
  box-sizing: border-box;
  cursor: pointer;
  background-color: #181a1f;
  height: 80px;
  padding: 0 24px;
  border-radius: 40px;
  font-weight: 800;
  font-size: 28px;
}

.ctaButton:hover {
  border: 2px solid white;
  padding: 0 22px;
  background-color: #9740c0;
}

@media only screen and (max-width: 1138px) {
  .heroHeading {
    font-size: 48px;
  }

  .heroSubHeading {
    font-size: 24px;
    margin-bottom: 48px;
  }

  .heroButton {
    font-size: 24px;
    height: 60px;
  }

  .heroData {
    font-size: 18px;
  }

  .featureContainer {
    gap: 24px;
    padding: 0 24px;
  }

  .ctaHeading {
    font-size: 48px;
  }
  .ctaSubHeading {
    font-size: 24px;
  }
  .ctaButton {
    height: 60px;
    font-size: 18px;
  }
}

@media only screen and (max-width: 812px) {
  .heroHeading {
    font-size: 36px;
  }

  .heroSubHeading {
    font-size: 18px;
    margin-bottom: 24px;
  }

  .heroButton {
    font-size: 18px;
    height: 40px;
    padding: 0 18px;
  }

  .heroButton:hover {
    padding: 0 16px;
  }

  .heroData {
    font-size: 14px;
  }

  .featureContainer {
    display: block;
    padding: 0;
  }

  .feature {
    margin: auto;
    margin-bottom: 48px;
  }

  .cta {
    display: block;
    padding: 36px;
  }
  .ctaHeading {
    font-size: 36px;
  }
  .ctaSubHeading {
    font-size: 24px;
  }
  .ctaTextWrapper {
    margin-bottom: 36px;
  }
  .ctaButtonWrapper {
    text-align: center;
  }
}

@media only screen and (max-width: 650px) {
  .rootPage {
    text-align: center;
  }

  .logo {
    font-size: 24px;
  }

  .hero {
    display: block;
    margin-bottom: 48px;
  }

  .heroTextContainer {
    margin-bottom: 24px;
  }

  .heroImageContainer {
    width: 80%;
    margin: auto;
  }
  .section {
    padding: 0;
  }
  .sectionUnderline {
    margin-bottom: 24px;
  }
  .step {
    font-size: 18px;
  }

  .sectionHeading {
    font-size: 36px;
  }
}

@media only screen and (max-width: 450px) {
  .header {
    padding: 24px;
  }
  .marginWrapper {
    margin: 0 24px;
  }

  .loginButton {
    font-size: 12px;
    height: 28px;
    padding: 0 12px;
  }

  .step {
    font-size: 14px;
  }
  .sectionHeading {
    font-size: 28px;
  }
  .sectionUnderline {
    height: 4px;
    width: 24px;
  }

  .feature {
    padding: 24px;
    border-radius: 20px;
  }
  .featureHeading {
    font-size: 18px;
  }
  .featureSubHeading {
    font-size: 14px;
  }

  .cta {
    margin: 48px 0;
  }
  .ctaTextWrapper {
    margin-bottom: 18px;
  }
  .ctaHeading {
    font-size: 24px;
  }
  .ctaSubHeading {
    font-size: 14px;
  }
  .ctaButton {
    height: 40px;
    font-size: 14px;
  }
}
